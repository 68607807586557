import { createContext } from "react";
import { Outlet } from "react-router-dom";

const UserContext = createContext({});

export const UserProvider = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const { controller } = useController();

  // useEffect(() => {
  //   const getUser = async () => {
  //     controller(
  //       async () => {
  //         await authApi.verifyToken();
  //       },
  //       {
  //         onError() {
  //           const search = new URLSearchParams(`redirect=${location.pathname}`);
  //           const queries = search.toString();

  //           navigate(`${ROUTERS.LOGIN}${queries ? `?${queries}` : ""}`);
  //         }
  //       }
  //     );
  //   };

  //   getUser();
  // }, []);
  return (
    <UserContext.Provider value={{}}>
      <Outlet />
    </UserContext.Provider>
  );
};
