import styled from "@emotion/styled";
import { Button } from "antd";
import {
  createContext,
  Dispatch,
  lazy,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { RepoModel } from "@types";
import { GraphInSvg, SysAssessmentSvg } from "@assets/svg";
import { ExplorationContent } from "@pages/exploration/ExplorationContent";

export const ExportBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary100}80;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

const ProjectContext = createContext<{ project: RepoModel }>({} as { project: RepoModel });

export const useProject = () => {
  return useContext(ProjectContext);
};

const RouteContext = createContext<{
  recentRoutes: Record<string, string>;
  setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
}>(
  null as unknown as {
    recentRoutes: Record<string, string>;
    setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
  }
);

export const useExplorationRoute = () => {
  return useContext(RouteContext);
};

export const ExplorationRouter = () => {
  const navigate = useNavigate();
  const { projectId, explorationCategory } = useParams();

  const [recentRoutes, setRecentRoutes] = useState<Record<string, string>>({});

  const mapRoute = useCallback((route: string) => {
    if (route === "inventory") return "Inventory";
    if (route === "complexity") return "Complexity";
    if (route === "data") return "Data inventory";
    if (route === "graph") return "Dependency Graph";
    if (route === "entrypoints") return "Entrypoints";
  }, []);

  const onChangeSegmented = useCallback((value: string) => {
    const key = value.split("/").at(-1) || value.split("/").at(-2) || "assets";
    const route = `/exploration/${projectId}/${value}`;

    const keyRoute = mapRoute(key);

    navigate(route);
    if (keyRoute)
      setRecentRoutes(prev => {
        const routes = { ...prev };

        routes[keyRoute] = `${window.location.pathname}${window.location.search}`;
        return routes;
      });
  }, []);

  useEffect(() => {
    const { pathname, search } = window.location;
    const route = search
      ? "reverse"
      : pathname.split("/").at(-1) || pathname.split("/").at(-2) || "inventory";

    const keyRoute = mapRoute(route);

    if (keyRoute)
      setRecentRoutes(prev => {
        const routes = { ...prev };

        routes[keyRoute] = `${pathname}${search}`;
        return routes;
      });
  }, []);

  return (
    <TopbarLayout
      segmented={{
        options: [
          {
            label: "Assessment",
            value: "assets",
            Icon: () => <SysAssessmentSvg className='icon' />,
            children: [
              {
                label: "Inventory",
                value: "inventory"
              },
              {
                label: "Complexity",
                value: "complexity"
              },
              {
                label: "Data Inventory",
                value: "data"
              }
            ]
          },
          {
            label: "Reverse Engineering",
            value: "reverse",
            Icon: () => <GraphInSvg className='icon' />,
            children: [
              {
                label: "Dependency Graph",
                value: "graph"
              },
              {
                label: "Entrypoints",
                value: "entrypoints"
              }
            ]
          }
        ],
        onChange: onChangeSegmented,
        defaultValue: "assets",
        value: explorationCategory
      }}
    >
      <RouteContext.Provider value={{ recentRoutes, setRecentRoutes }}>
        <ExplorationContent recentRoutes={recentRoutes} setRecentRoutes={setRecentRoutes} />
      </RouteContext.Provider>
    </TopbarLayout>
  );
};
