/* eslint-disable */
export enum ENVIRONMENT {
  DEVELOPMENT = "development",
  PRODUCTION = "production"
}

export const DEFAULT_QUERIES = { page: 0, pageSize: 10 };

export const isShowChatBox = true;

/* eslint-enable */
