import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { FilterState, GlobalFilterState } from "./type";

const initialFilterReportsState: FilterState = {
  searchText: "",
  type: null,
  status: null,
  page: undefined
};

const initialState: GlobalFilterState = {
  reports: { ...initialFilterReportsState },
  database: { ...initialFilterReportsState }
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setReportsFilter: (state, action: PayloadAction<Partial<FilterState>>) => {
      state.reports = { ...state.reports, ...action.payload };
    },
    resetReportsFilter: state => {
      state.reports = { ...initialFilterReportsState };
    },
    setDatabaseFilter: (state, action: PayloadAction<Partial<FilterState>>) => {
      state.reports = { ...state.reports, ...action.payload };
    },
    resetDatabaseFilter: state => {
      state.reports = { ...initialFilterReportsState };
    }
  }
});
const persistConfig = {
  key: "filters",
  storage,
  whitelist: ["reports"]
};

// > Export
// * Action
export const actions = { ...filterSlice.actions };
// * Reducer
export const reducer = persistReducer(persistConfig, filterSlice.reducer);
