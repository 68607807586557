import { ModernizationInput, ModernizationResponse } from "@types";

import { API_ENPOINTS } from "./endpoints";
import { HttpClient } from "./httpClient";

export default class ModernizationApi {
  static inferenceModernization = (params: ModernizationInput) => {
    return HttpClient.post<ModernizationResponse>(API_ENPOINTS.INFERENCE_MODERNIZATION, params);
  };
}
