import { allColors } from "@style";
import { DotSvg } from "@assets/svg";
import { RepoStatus } from "@types";

import { Flex, Typography } from "./ui";

export function StatusLabel({ status }: { status: RepoStatus }) {
  return (
    <Flex
      style={{
        width: "max-content",
        height: 24,
        padding: "4px",
        background: allColors.neutral3,
        borderRadius: "24px"
      }}
      gap={6}
      align='center'
    >
      <DotSvg
        width={12}
        height={11}
        fill={
          status === "processing"
            ? "rgba(250, 173, 20, 1)"
            : status === "processed"
            ? "rgba(90, 160, 100, 1)"
            : "rgba(191, 90, 242, 1)"
        }
      />
      <Typography level='caption-12r' color='primary10'>
        {`${status.charAt(0).toUpperCase()}${status.slice(1)}`}
      </Typography>
    </Flex>
  );
}
