import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { api } from "@api";
import { appActions, userActions } from "@store";
import { ROUTERS } from "@defines";

export const useLogout = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cleanStore = () => {
    dispatch(userActions.clean());
    dispatch(appActions.clean());
  };

  const logout = async () => {
    try {
      setLoading(true);

      api.removeToken();

      cleanStore();

      const search = new URLSearchParams(`redirect=${location.pathname}`);
      const queries = search.toString();

      navigate(`${ROUTERS.LOGIN}${queries ? `?${queries}` : ""}`);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { logout, loading };
};
