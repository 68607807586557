/* eslint-disable @typescript-eslint/no-explicit-any */
import { Layout } from "antd";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Flex, Typography } from "@components";
import { allColors } from "@style";
import { HEADER_DASHBOARD_HEIGHT } from "@layouts/TopbarLayout/config";
import { CloseSvg } from "@assets/svg";
import { AssessmentData, FileGroup } from "@types";
import { useController } from "@hooks";
import { repositoryApi } from "@api";

import {
  Node as NodeGraph,
  EntryPoint as EntryPointGraph,
  GraphData
} from "./entrypoints/fake/graph";

const { Header } = Layout;

type EntryPoint = { value: string; type: "bms" | "jcl"; id: string };

type Edge = { source: string; target: string; id: string };
const getConnectedNodesAndEdges = (
  entryId: string,
  totalGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  }
): {
  nodes: Array<{
    _id: string;
    data: { label: string };
    fileType: FileGroup;
    loc?: number | null;
    complexity?: number | null;
    name: string;
  }>;
  edges: Array<{
    id: string;
    source: string;
    target: string;
  }>;
} => {
  const visitedNodes = new Set<string>();
  const visitedEdges: Edge[] = [];

  const traverse = (nodeId: string) => {
    visitedNodes.add(nodeId);

    // Find edges where the source is the current nodeId
    totalGraph.edges.forEach(edge => {
      if (edge.source === nodeId && !visitedNodes.has(edge.target)) {
        visitedEdges.push(edge);
        traverse(edge.target); // Recursively traverse the child node
      }
    });
  };

  traverse(entryId);

  // Filter the nodes that were visited
  const nodes = totalGraph.nodes.filter(node => visitedNodes.has(node._id));

  return { nodes, edges: visitedEdges };
};

type EntryPointValue = {
  entries: EntryPoint[];
  setEntries: Dispatch<SetStateAction<EntryPoint[]>>;
  selectedEntries: Record<string, any>;
  setSelectedEntries: Dispatch<SetStateAction<Record<string, any>>>;
  totalGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  } | null;
  selectedGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  };
  selectedEntry: string | null;
  setSelectedEntry: Dispatch<SetStateAction<string | null>>;
  showNode: Record<string, boolean>;
  setShowNode: Dispatch<SetStateAction<Record<string, boolean>>>;
  select: "jcl" | "bms" | null;
  setSelect: Dispatch<SetStateAction<"jcl" | "bms" | null>>;
  graphData: GraphData | null;
  inventoryData: AssessmentData | null;
};

const EntryPointContext = createContext<EntryPointValue>(null as unknown as EntryPointValue);

export const useEntryPoint = () => {
  return useContext(EntryPointContext);
};

export const ExplorationContent = ({
  recentRoutes,
  setRecentRoutes
}: {
  recentRoutes: Record<string, string>;
  setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const { projectId } = useParams();
  const [entries, setEntries] = useState<Array<EntryPoint>>([]);
  const [selectedEntries, setSelectedEntries] = useState<Record<string, any>>({});
  const [showNode, setShowNode] = useState<Record<string, boolean>>({});
  const [selectedEntry, setSelectedEntry] = useState<string | null>(null);
  const [select, setSelect] = useState<"jcl" | "bms" | null>(null);
  const { controller } = useController();
  const [data, setData] = useState<GraphData | null>(null);
  const [inventoryData, setInventoryData] = useState<AssessmentData | null>(null);

  const [totalGraph, setTotalGraph] = useState<{
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  } | null>(null);

  const navigate = useNavigate();
  const { pathname, search } = window.location;
  const path = `${pathname}${search}`;

  useEffect(() => {
    const fetchGraphData = async () => {
      controller(async () => {
        if (!projectId) return;
        const [assessmentRes, graphRes] = await Promise.all([
          repositoryApi.getAssessRepository(projectId),
          repositoryApi.getDependencyGraph(projectId)
        ]);

        if (!assessmentRes || !graphRes) {
          return;
        }
        const graphData = graphRes.data.graph;

        setInventoryData(assessmentRes.data.assessments[0]?.result);
        setData(graphData);
        setEntries(
          graphData.entry_points.map((item: EntryPointGraph) => ({
            value: item.name,
            type: item.label === "BMS" ? "bms" : "jcl",
            id: item._id
          }))
        );
        setTotalGraph({
          edges: graphData.edges.map(({ target, source }: Edge) => ({
            id: `${source}-${target}`,
            source,
            target
          })),
          nodes: graphData.nodes.map(({ _id, label, name, loc, complexity }: NodeGraph) => ({
            _id,
            data: { label: name },
            fileType: label,
            loc,
            complexity,
            name
          }))
        });
      });
    };

    fetchGraphData();
  }, [projectId]);

  const selectedId = useMemo(() => selectedEntry?.split("-").at(0) ?? "", [selectedEntry]);

  const selectedGraph = useMemo(() => {
    if (!selectedId || !totalGraph) return { nodes: [], edges: [] };
    return getConnectedNodesAndEdges(selectedId, totalGraph);
  }, [selectedId, totalGraph]);

  useEffect(() => {
    const targetNodes = new Set(selectedGraph.edges.map(({ target }) => target));
    const parentNodes = selectedGraph.nodes
      .map(({ _id }) => _id)
      .filter(id => !targetNodes.has(id));
    setShowNode(
      selectedGraph.nodes.reduce<Record<string, boolean>>((result, { _id }) => {
        const next = { ...result };

        next[_id] = parentNodes.includes(_id);
        return next;
      }, {})
    );
  }, [selectedGraph]);

  useEffect(() => {
    const firstEntry = entries[0];

    if (firstEntry) {
      setSelectedEntry(`${firstEntry.id}-${firstEntry.value}`);
    }
  }, [entries]);

  return (
    <EntryPointContext.Provider
      value={{
        graphData: data,
        entries,
        setEntries,
        selectedEntries,
        setSelectedEntries,
        totalGraph,
        selectedGraph,
        selectedEntry,
        setSelectedEntry,
        showNode,
        setShowNode,
        select,
        setSelect,
        inventoryData
      }}
    >
      <Header
        style={{
          height: HEADER_DASHBOARD_HEIGHT,
          borderBottom: `1px solid ${allColors.neutral6}`,
          background: allColors.neutral4,
          padding: "0 16px"
        }}
      >
        <Flex
          style={{ width: "100%", height: "100%", overflow: "auto", padding: "0 16px " }}
          gap={16}
          justify='flex-start'
          align='center'
        >
          {Object.entries(recentRoutes).map(([key, value]) => (
            <Flex
              gap={8}
              style={{
                borderRadius: "4px",
                background: path === value ? allColors.neutral2 : allColors.neutral1,
                padding: "8px",
                cursor: "pointer",
                minWidth: "max-content"
              }}
              onClick={() => navigate(value)}
              key={key}
              center
            >
              <Typography
                level='caption-12m'
                color={path === value ? "primary10" : "neutral6"}
                style={{ whiteSpace: "nowrap" }}
              >
                {key}
              </Typography>
              <CloseSvg
                stroke={path === value ? allColors.primary10 : allColors.neutral6}
                onClick={e => {
                  e.stopPropagation();
                  if (Object.keys(recentRoutes).length === 1) return;
                  const routes = { ...recentRoutes };

                  delete routes[key];

                  setRecentRoutes(routes);

                  if (path === value) navigate(Object.values(routes)[0]);
                }}
              />
            </Flex>
          ))}
        </Flex>
      </Header>
      <Layout>
        <Flex style={{ background: allColors.neutral4, flex: "1 1 0%", height: "100%" }}>
          <Outlet />
        </Flex>
      </Layout>
    </EntryPointContext.Provider>
  );
};
